
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Натиснути «Поділитися»`,
's37a9e8aec5713460': `Детальніше`,
's5c6aad7a7e4a1437': `3) Натиснути «На Початковий екран»`,
's6196153c4b0c1ea0': `Встановити`,
's8d0173763d91830c': `Цей веб-сайт має функціонал додатка. Додайте його на Початковий екран, щоб отримати додаткові можливості та зручність використання.`,
'sa5ef80b4bb9b39f8': `Згорнути`,
'sc79fd7641eb9a975': `Сховати Інструкцію`,
'scdaf4bbff76674c8': `На Початковий экран`,
'sdca23702207fba73': `Цей веб-сайт має функціонал додатка. Встановіть його, щоб отримати додаткові можливості та зручність використання.`,
'sdfe23506a3b0cdc8': `1) Відкрити в Safari браузері`,
    };
  